<template>
    <div>
        <!-- <div class="row topmainrow">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="topmainlayer card">
          <div class="title"><span class="title2">Andami –</span> <span class="title3">CMMS</span></div>
          <div class="mainbtns">
            <q-btn flat round color="orange-7" icon="handyman" @click="goPage('/sop/swp/safeWorkPermit')"><q-tooltip>작업허가서</q-tooltip></q-btn>
            <q-btn flat round color="orange-7" icon="assessment" @click="goPage('/pm/workOrderRequest')"><q-tooltip>정비요청</q-tooltip></q-btn><br>
            <q-btn flat round color="orange-7" icon="pending_actions" @click="goPage('/pc/preventiveCheckManage')"><q-tooltip>일상점검</q-tooltip></q-btn>
            <q-btn flat round color="orange-7" icon="more_time" @click="goPage('/pm/preventiveMaintenance')"><q-tooltip>예방정비(PM)</q-tooltip></q-btn>
          </div>
        </div>
      </div>
    </div> -->
        <div class="row maindashboard">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="cardcontents">
                    <div class="card-header">메뉴 이동하기</div>
                    <div class="card-body nopadgrid card-body-110">
                        <div class="row">
                            <div
                                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                            >
                                <div
                                    class="maincard card"
                                    @click="goPage('/wod/workOrderRequest')"
                                >
                                    <q-img
                                        :src="
                                            require('@/assets/images/request2.jpg')
                                        "
                                        class="image-thumbnail cursor-pointer cursor-pointer"
                                        style="
                                            height: 100px;
                                            max-width: 100%;
                                            border-radius: 15px;
                                        "
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="width: 100%; height: 100%"
                                        >
                                            <div class="flex-grow-1">
                                                <div
                                                    class="text-xs fw-bold text-white mb-1 h5"
                                                >
                                                    <span class="material-icons"
                                                        >record_voice_over</span
                                                    >
                                                    정비요청(통지)
                                                </div>
                                            </div>
                                        </div>
                                    </q-img>
                                </div>
                            </div>
                            <div
                                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                            >
                                <div
                                    class="maincard card"
                                    @click="goPage('/wod/plan/workOrderPlan')"
                                >
                                    <q-img
                                        :src="
                                            require('@/assets/images/work.jpg')
                                        "
                                        class="image-thumbnail cursor-pointer cursor-pointer"
                                        style="
                                            height: 100px;
                                            max-width: 100%;
                                            border-radius: 15px;
                                        "
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="width: 100%; height: 100%"
                                        >
                                            <div class="flex-grow-1">
                                                <div
                                                    class="text-xs fw-bold text-white mb-1 h5"
                                                >
                                                    <span class="material-icons"
                                                        >engineering</span
                                                    >
                                                    정비오더(W/O)
                                                </div>
                                                <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                                                <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.riskAssessCnt + '건' }}</span> -->
                                                <span
                                                    :class="[
                                                        'txt-box-grid text-red-box',
                                                        'text-position',
                                                    ]"
                                                    >{{
                                                        "금일 오더발행 3건"
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </q-img>
                                </div>
                            </div>
                            <div
                                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                            >
                                <div
                                    class="maincard card"
                                    @click="
                                        goPage('/wod/plan/workOrderDailyPlan')
                                    "
                                >
                                    <q-img
                                        :src="
                                            require('@/assets/images/todayplan.jpg')
                                        "
                                        class="image-thumbnail cursor-pointer"
                                        style="
                                            height: 100px;
                                            max-width: 100%;
                                            border-radius: 15px;
                                        "
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="width: 100%; height: 100%"
                                        >
                                            <div class="flex-grow-1">
                                                <div
                                                    class="text-xs fw-bold text-white mb-1 h5"
                                                >
                                                    <span class="material-icons"
                                                        >calendar_today</span
                                                    >
                                                    일일작업계획
                                                </div>
                                                <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                                                <span
                                                    :class="[
                                                        'txt-box-grid text-red-box',
                                                        'text-position',
                                                    ]"
                                                    >{{ "금일 3건" }}</span
                                                >
                                            </div>
                                        </div>
                                    </q-img>
                                </div>
                            </div>
                            <div
                                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                            >
                                <div
                                    class="maincard card"
                                    @click="goPage('/mdm/fim/equipmentManage')"
                                >
                                    <q-img
                                        :src="
                                            require('@/assets/images/order.jpg')
                                        "
                                        class="image-thumbnail cursor-pointer"
                                        style="
                                            height: 100px;
                                            max-width: 100%;
                                            border-radius: 15px;
                                        "
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="width: 100%; height: 100%"
                                        >
                                            <div class="flex-grow-1">
                                                <div
                                                    class="text-xs fw-bold text-white mb-1 h5"
                                                >
                                                    <span class="material-icons"
                                                        >handyman</span
                                                    >
                                                    설비마스터
                                                </div>
                                                <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ '금일: ' + data.workPermitCnt +'건' }}</span> -->
                                            </div>
                                        </div>
                                    </q-img>
                                </div>
                            </div>
                            <div
                                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                            >
                                <div
                                    class="maincard card"
                                    @click="
                                        goPage(
                                            '/sop/mim/inspection/equipmentInspection'
                                        )
                                    "
                                >
                                    <q-img
                                        :src="
                                            require('@/assets/images/safetycheck.jpg')
                                        "
                                        class="image-thumbnail cursor-pointer"
                                        style="
                                            height: 100px;
                                            max-width: 100%;
                                            border-radius: 15px;
                                        "
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="width: 100%; height: 100%"
                                        >
                                            <div class="flex-grow-1">
                                                <div
                                                    class="text-xs fw-bold text-white mb-1 h5"
                                                >
                                                    <span class="material-icons"
                                                        >query_stats</span
                                                    >
                                                    예방점검
                                                </div>
                                                <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                                                <span
                                                    :class="[
                                                        'txt-box-grid text-red-box',
                                                        'text-position',
                                                    ]"
                                                    >{{
                                                        Number(
                                                            data.safetyFlag
                                                        ) === 0
                                                            ? "금일: 점검완료"
                                                            : "금일: 점검필요"
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </q-img>
                                </div>
                            </div>
                            <div
                                class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                            >
                                <div
                                    class="maincard card"
                                    @click="goPage('/sop/ibm/impr')"
                                >
                                    <q-img
                                        :src="
                                            require('@/assets/images/improve.jpg')
                                        "
                                        class="image-thumbnail cursor-pointer"
                                        style="
                                            height: 100px;
                                            max-width: 100%;
                                            border-radius: 15px;
                                        "
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="width: 100%; height: 100%"
                                        >
                                            <div class="flex-grow-1">
                                                <div
                                                    class="text-xs fw-bold text-white mb-1 h5"
                                                >
                                                    <span class="material-icons"
                                                        >security</span
                                                    >
                                                    점검 개선관리
                                                </div>
                                                <!-- <span :class="['txt-box-grid text-red-box', 'text-position']">{{ data.imprCompleteCnt + '/' + data.imprAllCnt + '건' }}</span> -->
                                                <span
                                                    :class="[
                                                        'txt-box-grid text-red-box',
                                                        'text-position',
                                                    ]"
                                                    >{{ "지연 1건" }}</span
                                                >
                                            </div>
                                        </div>
                                    </q-img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="cardcontents">
                    <div class="card-header">
                        정비오더 현황
                        <div
                            class="card-more card-more-txt"
                            @click="goPage('/wod/plan/workOrderPlan')"
                        >
                            상세보기
                        </div>
                        <div class="card-more main-header-input">
                            <c-plant
                                type="search"
                                name="plantCd"
                                :isFirstValue="false"
                                label=""
                                v-model="plantSearchParam.plantCd"
                                @datachange="ordPlanList"
                            />
                        </div>
                        <div class="card-more main-header-input3">
                            <c-datepicker
                                :range="true"
                                label=""
                                name="plantDts"
                                v-model="plantSearchParam.plantDts"
                                @datachange="ordPlanList"
                            />
                        </div>
                    </div>
                    <div class="card-body">
                        <c-table
                            ref="table1"
                            tableId="table1"
                            :isTitle="false"
                            :isDashboard="true"
                            :columnSetting="false"
                            :usePaging="false"
                            :filtering="false"
                            :isFullScreen="false"
                            :hideBottom="true"
                            gridHeight="180px"
                            :columns="ordPlanGrid.columns"
                            :data="ordPlanGrid.data"
                            @linkClick="rowClick1"
                        >
                        </c-table>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="cardcontents">
                    <div class="card-header">
                        일일작업계획 현황
                        <div
                            class="card-more card-more-txt"
                            @click="goPage('/wod/plan/workOrderDailyPlan')"
                        >
                            상세보기
                        </div>
                        <div class="card-more main-header-input">
                            <c-plant
                                type="search"
                                name="plantCd"
                                :isFirstValue="false"
                                label=""
                                v-model="dailySearchParam.plantCd"
                                @datachange="ordDailyList"
                            />
                        </div>
                        <div class="card-more main-header-input3">
                            <c-datepicker
                                :range="true"
                                label=""
                                name="workDts"
                                v-model="dailySearchParam.workDts"
                                @datachange="ordDailyList"
                            />
                        </div>
                    </div>
                    <div class="card-body">
                        <c-table
                            ref="table2"
                            tableId="table2"
                            :isTitle="false"
                            :isDashboard="true"
                            :columnSetting="false"
                            :usePaging="false"
                            :filtering="false"
                            :isFullScreen="false"
                            :hideBottom="true"
                            gridHeight="180px"
                            :columns="dailyPlanGrid.columns"
                            :data="dailyPlanGrid.data"
                            @linkClick="rowClick2"
                        >
                        </c-table>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-6">
        <div class="cardcontents">
          <div class="card-header">
            전체 W/O 이행율
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCd"
                :isFirstValue="false"
                label=""
                v-model="woSearchParam.plantCd"
                @datachange="woFullFillList"/>
            </div>
          </div>
          <div class="card-body">
            <div class="card-body-box text-xs fw-bold">
              <span class="icountup">
                <q-icon color="#4d63bc" name="task_alt"/>
                <ICountUp :startVal="0" :endVal="Number(woData.fullFillPercent)" :decimals="0" 
                  :duration="4" :options="countOptions1" />
              </span>
              <span style="text-align:left;display:inline-block;padding:25px 0px 0px 10px">
                <div class="progress-shell">
                  <div class="progress-bar" :style="{ width: woData.fullFillPercent + '%' }">
                  </div>
                </div>
              </span>
              <span class="itemlist">
                <q-list dense>
                  <q-item v-ripple>
                    <q-item-section avatar>
                      <q-icon color="blue" name="info" />
                    </q-item-section>
                    <q-item-section>전체 W/O</q-item-section>
                    <q-item-section>
                      <font style="font-size:1.2em;font-weight:700;" color="#C10015">
                        {{woData.allCnt + '건'}}
                      </font>
                    </q-item-section>
                  </q-item>

                  <q-item v-ripple>
                    <q-item-section avatar>
                      <q-icon color="green" name="info" />
                    </q-item-section>
                    <q-item-section>완료 W/O</q-item-section>
                    <q-item-section>
                      <font style="font-size:1.2em;font-weight:700;" color="#C10015">
                        {{woData.completeCnt + '건'}}
                      </font>
                    </q-item-section>
                  </q-item>

                  <q-item v-ripple>
                    <q-item-section avatar>
                      <q-icon color="red" name="info" />
                    </q-item-section>
                    <q-item-section>미완료 W/0</q-item-section>
                    <q-item-section>
                      <font style="font-size:1.2em;font-weight:700;" color="#C10015">
                        {{woData.noCompleteCnt + '건'}}
                      </font>
                    </q-item-section>
                  </q-item>
                </q-list>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-6">
        <div class="cardcontents">
          <div class="card-header">
            PM 이행율
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCd"
                :isFirstValue="false"
                label=""
                v-model="pmSearchParam.plantCd"
                @datachange="pmFullFillList"/>
            </div>
          </div>
          <div class="card-body">
            <div class="card-body-box text-xs fw-bold">
              <span class="icountup">
                <q-icon color="#4d63bc" name="task_alt"/>
                <ICountUp :startVal="0" :endVal="Number(pmData.fullFillPercent)" :decimals="0" 
                  :duration="4" :options="countOptions2" />
              </span>
              <span style="text-align:left;display:inline-block;padding:25px 0px 0px 10px">
                <div class="progress-shell">
                  <div class="progress-bar" :style="{ width: pmData.fullFillPercent + '%' }">
                  </div>
                </div>
              </span>
              <span class="itemlist">
                <q-list dense>
                  <q-item v-ripple>
                    <q-item-section avatar>
                      <q-icon color="blue" name="info" />
                    </q-item-section>
                    <q-item-section>전체 PM</q-item-section>
                    <q-item-section>
                      <font style="font-size:1.2em;font-weight:700;" color="#C10015">
                        {{pmData.allCnt + '건'}}
                      </font>
                    </q-item-section>
                  </q-item>

                  <q-item v-ripple>
                    <q-item-section avatar>
                      <q-icon color="green" name="info" />
                    </q-item-section>
                    <q-item-section>완료 PM</q-item-section>
                    <q-item-section>
                      <font style="font-size:1.2em;font-weight:700;" color="#C10015">
                        {{pmData.completeCnt + '건'}}
                      </font>
                    </q-item-section>
                  </q-item>

                  <q-item v-ripple>
                    <q-item-section avatar>
                      <q-icon color="red" name="info" />
                    </q-item-section>
                    <q-item-section>미완료 PM</q-item-section>
                    <q-item-section>
                      <font style="font-size:1.2em;font-weight:700;" color="#C10015">
                        {{pmData.noCompleteCnt + '건'}}
                      </font>
                    </q-item-section>
                  </q-item>
                </q-list>
              </span>
            </div>
          </div>
        </div>
      </div> -->
            <div class="col-6">
                <div class="card cardcontents">
                    <div class="card-header">
                        설비 가용성 분석 [최근 고장일 기준 10개 조회]
                        <div
                            class="card-more card-more-txt"
                            @click="goPage('/sts/wha/equipMttrMtbfStatus')"
                        >
                            상세보기
                        </div>
                        <div class="card-more main-header-input">
                            <c-plant
                                type="search"
                                name="plantCd"
                                label=""
                                v-model="searchParam.plantCd"
                                @datachange="mtbfList"
                            />
                        </div>
                    </div>
                    <div class="card-body">
                        <c-table
                            ref="table3"
                            tableId="table3"
                            :isTitle="false"
                            :isDashboard="true"
                            :columnSetting="false"
                            :usePaging="false"
                            :filtering="false"
                            :isFullScreen="false"
                            :hideBottom="true"
                            gridHeight="320px"
                            :columns="mtbfGrid.columns"
                            :data="mtbfGrid.data"
                        >
                        </c-table>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card cardcontents">
                    <div class="card-header">
                        정비 오더 현황
                        <div class="card-more main-header-input">
                            <c-plant
                                type="search"
                                name="plantCd"
                                label=""
                                v-model="searchParam2.plantCd"
                                @datachange="woList"
                            />
                        </div>
                        <div class="card-more main-header-input4">
                            <c-datepicker
                                :range="true"
                                type="month"
                                v-model="period"
                                @datachange="woList"
                            />
                        </div>
                    </div>
                    <div class="card-body">
                        <apexchart
                            ref="woChart"
                            height="317px"
                            :options="woChart.chartOptions"
                            :series="woChart.series"
                        ></apexchart>
                        <!-- <apexchart 
              ref="mtbfChart" 
              height="317px" 
              type="scatter"
              :options="mtbfChart.chartOptions" 
              :series="mtbfChart.series">
            </apexchart> -->
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card cardcontents">
                    <div class="card-header">
                        정비/점검 일정
                        <div class="card-more main-header-input">
                            <c-plant
                                type="search"
                                name="plantCd"
                                label=""
                                v-model="calendarParam.plantCd"
                                @datachange="calendarList"
                            />
                        </div>
                        <div class="card-more main-header-input">
                            <c-select
                                codeGroupCd="WO_TYPE"
                                type="none"
                                itemText="codeName"
                                itemValue="code"
                                name="woType"
                                label=""
                                v-model="calendarParam.woType"
                                @datachange="calendarList"
                            ></c-select>
                        </div>
                        <div class="card-more main-header-input">
                            <q-btn
                                color="grey-8"
                                class="calMoveBtn"
                                outline
                                rounded
                                icon="arrow_back_ios"
                                label="이전달"
                                @click="onPrev"
                            />
                            <q-btn
                                color="grey-8"
                                class="calMoveBtn"
                                outline
                                rounded
                                :label="title"
                                style="width: 200px"
                                @click="onToday"
                            />
                            <q-btn
                                color="grey-8"
                                class="calMoveBtn"
                                outline
                                rounded
                                icon-right="arrow_forward_ios"
                                label="다음달"
                                @click="onNext"
                            />
                        </div>
                        <div class="card-more main-header-input">
                            <font
                                v-show="calendarParam.woType === 'WT00000001'"
                                style="
                                    font-size: 0.8em;
                                    font-weight: 400;
                                    color: black;
                                "
                            >
                                <q-chip color="orange" /> W/O발행 및 계획
                                &nbsp;&nbsp; <q-chip color="blue" /> W/O승인중
                                &nbsp;&nbsp;
                                <q-chip color="deep-purple-6" /> 작업중
                                &nbsp;&nbsp; <q-chip color="yellow" /> 작업
                                승인중 &nbsp;&nbsp;
                                <q-chip color="green" /> W/O완료 &nbsp;&nbsp;
                                <q-chip color="red-6" /> W/O반려 &nbsp;&nbsp;
                            </font>
                            <font
                                v-show="calendarParam.woType === 'WT00000002'"
                                style="
                                    font-size: 0.8em;
                                    font-weight: 400;
                                    color: black;
                                "
                            >
                                <q-chip color="orange" /> 계획수립 &nbsp;&nbsp;
                                <q-chip color="deep-purple-6" /> 점검중
                                &nbsp;&nbsp; <q-chip color="green" /> 점검완료
                                &nbsp;&nbsp; <q-chip color="red-6" /> 지연
                                &nbsp;&nbsp;
                            </font>
                            <font
                                v-show="calendarParam.woType === 'WT00000003'"
                                style="
                                    font-size: 0.8em;
                                    font-weight: 400;
                                    color: black;
                                "
                            >
                                <q-chip color="orange" /> W/O발행 및 계획
                                &nbsp;&nbsp; <q-chip color="blue" /> W/O승인중
                                &nbsp;&nbsp;
                                <q-chip color="deep-purple-6" /> 작업중
                                &nbsp;&nbsp; <q-chip color="yellow" /> 작업
                                승인중 &nbsp;&nbsp;
                                <q-chip color="green" /> W/O완료 &nbsp;&nbsp;
                                <q-chip color="red-6" /> W/O반려 &nbsp;&nbsp;
                                <q-chip color="grey-6" />
                                차기정비예정/오더작성예정 &nbsp;&nbsp;
                            </font>
                        </div>
                    </div>
                    <div class="card-body">
                        <q-calendar
                            ref="calendar"
                            v-model="selectedDate"
                            view="month"
                            locale="ko-kr"
                            :day-height="dayHeight"
                            :day-min-height="dayMinHeight"
                            @change="onChange"
                            show-work-weeks
                        >
                            <template #week="{ week }">
                                <template>
                                    <q-badge
                                        v-for="(
                                            computedEvent, index
                                        ) in getWeekEvents(week)"
                                        :key="index"
                                        class="q-row-event"
                                        :class="
                                            badgeClasses(computedEvent, 'day')
                                        "
                                        :style="
                                            badgeStyles(
                                                computedEvent,
                                                'day',
                                                week.length
                                            )
                                        "
                                        @click="openJobDetail(computedEvent)"
                                    >
                                        <template v-if="computedEvent.event">
                                            <q-icon
                                                :name="computedEvent.event.icon"
                                                class="q-mr-xs"
                                            ></q-icon>
                                            <span class="ellipsis">{{
                                                computedEvent.event.title
                                            }}</span>
                                        </template>
                                        <template v-if="computedEvent.event">
                                            <q-tooltip
                                                >정비오더 발행부서:
                                                {{
                                                    computedEvent.event
                                                        .workDeptName
                                                }}<br />설비:
                                                {{
                                                    computedEvent.event
                                                        .equipmentName
                                                }}<br />{{
                                                    computedEvent.event
                                                        .woWorkPlanStepName
                                                }}</q-tooltip
                                            >
                                        </template>
                                    </q-badge>
                                </template>
                            </template>
                        </q-calendar>
                    </div>
                </div>
            </div>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>

<script>
// import ICountUp from "vue-countup-v2";
import selectConfig from "@/js/selectConfig";
import QCalendarm from "@quasar/quasar-ui-qcalendar";
import { QCalendar } from "@quasar/quasar-ui-qcalendar";
import "@quasar/quasar-ui-qcalendar/dist/index.css";
import VueApexCharts from "vue-apexcharts";
export default {
    name: "main-dashboard",
    components: {
        // ICountUp,
        QCalendar,
        apexchart: VueApexCharts,
    },
    props: {},
    data() {
        return {
            searchParam: {
                plantCd: null,
            },
            searchParam2: {
                plantCd: null,
                year: "",
            },
            calendarParam: {
                plantCd: null,
                woType: "WT00000001",
            },
            title: "",
            dateFormatter: undefined,
            start: undefined,
            selectedDate: "",
            events: [],
            monthDaysFirst: [],
            monthDaysLast: [],
            popupOptions: {
                target: null,
                title: "",
                suffixChip: "",
                visible: false,
                top: "",
                param: {},
                closeCallback: null,
            },
            countOptions1: {
                useEasing: true,
                useGrouping: true,
                separator: ",",
                decimal: ".",
                prefix: "",
                suffix: "%",
            },
            countOptions2: {
                useEasing: true,
                useGrouping: true,
                separator: ",",
                decimal: ".",
                prefix: "",
                suffix: "%",
            },
            data: {},
            pmChart: {
                chartOptions: {
                    legend: {
                        show: false,
                    },
                    title: {
                        text: "",
                        floating: true,
                        offsetY: 330,
                        align: "center",
                        style: {
                            color: "#444",
                        },
                    },
                    chart: {
                        height: 350,
                        type: "bar",
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            horizontal: false,
                            columnWidth: "60%",
                            endingShape: "rounded",
                            distributed: true,
                            dataLabels: {
                                position: "top", // top, center, bottom
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val + "건";
                        },
                        offsetY: -20,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                        },
                    },
                    colors: ["#008FFB", "#4caf50", "#f44336"],
                    labels: {
                        style: {
                            colors: ["#008FFB", "#4caf50", "#f44336"],
                            fontSize: "12px",
                        },
                    },
                    xaxis: {
                        categories: ["전체", "완료", "미완료"],
                        position: "bottom",
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        crosshairs: {
                            fill: {
                                type: "gradient",
                                gradient: {
                                    colorFrom: "#D8E3F0",
                                    colorTo: "#BED1E6",
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                },
                            },
                        },
                        tooltip: {
                            enabled: true,
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                            formatter: function (val) {
                                return val + "건";
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "",
                        data: [],
                    },
                ],
                chartWidth: "80%",
            },
            chart: {
                chartOptions: {
                    title: {
                        // MTTF, MTBF
                        text: "",
                    },
                    chart: {
                        height: 700,
                        dropShadow: {
                            enabled: true,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "60%",
                            endingShape: "rounded",
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                        },
                    },
                    stroke: {
                        width: [3, 3, 6],
                        curve: "straight",
                    },
                    xaxis: {
                        categories: [
                            "1월",
                            "2월",
                            "3월",
                            "4월",
                            "5월",
                            "6월",
                            "7월",
                            "8월",
                            "9월",
                            "10월",
                            "11월",
                            "12월",
                        ],
                    },
                    yaxis: {
                        title: {
                            text: "시간",
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    grid: {
                        borderColor: "#e7e7e7",
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            sizeOffset: 6,
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return +val + " 시간";
                            },
                        },
                    },
                },
                series: [],
                chartWidth: "80%",
            },
            ordPlanGrid: {
                listUrl: "",
                columns: [
                    {
                        name: "plantName",
                        field: "plantName",
                        label: "사업장",
                        align: "center",
                        style: "width:80px",
                        sortable: true,
                    },
                    {
                        name: "woWorkPlanStepName",
                        field: "woWorkPlanStepName",
                        label: "진행상태",
                        align: "center",
                        style: "width:100px",
                    },
                    {
                        name: "workPlanName",
                        field: "workPlanName",
                        label: "정비오더명",
                        align: "left",
                        style: "width:200px",
                        type: "link",
                    },
                    // {
                    //   name: 'equipmentNo',
                    //   field: 'equipmentNo',
                    //   label: '대표설비Tag',
                    //   align: 'center',
                    //   style: 'width:100px',
                    // },
                    {
                        name: "planDtsStr",
                        field: "planDtsStr",
                        label: "작업계획기간",
                        align: "center",
                        style: "width:100px",
                    },
                ],
                data: [],
            },
            mtbfChart: {
                chartOptions: {
                    chart: {
                        height: 350,
                        type: "scatter",
                        zoom: {
                            enabled: true,
                            type: "xy",
                        },
                    },
                    xaxis: {
                        tickAmount: 10,
                        labels: {
                            formatter: function (val) {
                                return parseFloat(val).toFixed(1);
                            },
                        },
                    },
                    yaxis: {
                        tickAmount: 7,
                    },
                    series: [],
                },
            },
            woChart: {
                chartOptions: {
                    title: {},
                    chart: {
                        height: 700,
                        type: "bar",
                        dropShadow: {
                            enabled: true,
                            color: "#000",
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: "60%",
                            endingShape: "rounded",
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"],
                        },
                    },
                    stroke: {
                        width: [5, 5, 5],
                        curve: "straight",
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: "건",
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    grid: {
                        borderColor: "#e7e7e7",
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 0,
                        hover: {
                            sizeOffset: 6,
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return +val + " 건";
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "오더발행",
                        data: [],
                    },
                    {
                        name: "작업완료",
                        data: [],
                    },
                ],
                chartWidth: "80%",
            },
            dailyPlanGrid: {
                listUrl: "",
                columns: [
                    {
                        name: "plantName",
                        field: "plantName",
                        label: "사업장",
                        align: "center",
                        style: "width:100px",
                        sortable: true,
                    },
                    {
                        name: "workResultName",
                        field: "workResultName",
                        label: "정비오더명",
                        align: "left",
                        style: "width:220px",
                    },
                    {
                        name: "workResultWorkName",
                        field: "workResultWorkName",
                        label: "작업명",
                        align: "center",
                        style: "width:100px",
                    },
                    {
                        name: "workDeptName",
                        field: "workDeptName",
                        label: "작업부서/업체",
                        align: "center",
                        style: "width:140px",
                        sortable: true,
                    },
                    {
                        name: "workDt",
                        field: "workDt",
                        label: "작업일",
                        align: "center",
                        style: "width:100px",
                    },
                    {
                        name: "workEndFlagName",
                        field: "workEndFlagName",
                        label: "작업결과",
                        align: "center",
                        style: "width:100px",
                        type: "link",
                    },
                ],
                data: [],
            },
            mtbfGrid: {
                listUrl: "",
                graphUrl: "",
                columns: [
                    {
                        name: "equipment_name",
                        field: "equipment_name",
                        label: "설비",
                        align: "center",
                        style: "width:130px",
                    },
                    {
                        name: "mttr",
                        field: "mttr",
                        label: "MTTR<br>(시간)",
                        align: "right",
                        style: "width:60px",
                        helpcomment: "수리하는 평균 시간",
                    },
                    {
                        name: "mttf",
                        field: "mttf",
                        label: "MTTF<br>(시간)",
                        align: "right",
                        style: "width:60px",
                        helpcomment: "수리 후 고장까지의 평균시간",
                    },
                    {
                        name: "mtbf",
                        field: "mtbf",
                        label: "MTBF<br>(시간)",
                        align: "right",
                        style: "width:60px",
                        helpcomment: "평균 고장 간격<br>MTTR + MTTF",
                    },
                    {
                        name: "availability_str",
                        field: "availability_str",
                        label: "Availability",
                        align: "right",
                        style: "width:90px",
                        helpcomment: "MTTF / MTBF * 100",
                    },
                    {
                        name: "fix_percent_strength",
                        field: "fix_percent_strength",
                        label: "고장강도율",
                        style: "width:80px",
                        align: "center",
                        sortable: false,
                        helpcomment: "총 고장정지시간 / 부하시간 * 100",
                    },
                    {
                        name: "fix_percent_frequency",
                        field: "fix_percent_frequency",
                        label: "고장도수율",
                        style: "width:80px",
                        align: "center",
                        sortable: false,
                        helpcomment: "고장건수 / 부하시간 * 100",
                    },
                    {
                        name: "lapse_time",
                        field: "lapse_time",
                        label: "부하시간",
                        align: "right",
                        style: "width:60px",
                        helpcomment:
                            "설비가 총 가동해야 하는 시간<br>(정지시간 포함)",
                    },
                    {
                        name: "fix_count",
                        field: "fix_count",
                        label: "고장<br>(건)",
                        align: "right",
                        style: "width:40px",
                    },
                    {
                        name: "breakdown_times",
                        field: "breakdown_times",
                        label: "고장정지<br>시간(합계)",
                        align: "right",
                        style: "width:70px",
                    },
                ],
                data: [],
            },
            workOrderUrl: "",
            workDailyUrl: "",
            mtbfUrl: "",
            woFullFillUrl: "",
            pmFullFillUrl: "",
            plantSearchParam: {
                plantCd: null,
                workDeptCd: "",
                plantDts: ["2024-11-01", "2024-11-30"],
                // mainFlag: 'Y'
            },
            dailySearchParam: {
                plantCd: null,
                workDts: ["2024-11-01", "2024-11-30"],
                workDeptCd: "",
                // mainFlag: 'Y'
            },
            woSearchParam: {
                plantCd: null,
            },
            pmSearchParam: {
                plantCd: null,
            },
            woData: {
                allCnt: 0,
                completeCnt: 0,
                noCompleteCnt: 0,
                fullFillPercent: 0,
            },
            pmData: {
                allCnt: 0,
                completeCnt: 0,
                noCompleteCnt: 0,
                fullFillPercent: 0,
            },
            period: [],
            listUrl: "",
            listUrl2: "",
            listUrl3: "",
            dayHeight: 140,
            dayMinHeight: 140,
        };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
        Object.assign(this.$data, this.$options.data());
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {},
    methods: {
        init() {
            this.plantSearchParam.plantCd = this.$store.getters.user.plantCd;
            this.plantSearchParam.workDeptCd = this.$store.getters.user.deptCd;
            this.dailySearchParam.plantCd = this.$store.getters.user.plantCd;
            this.dailySearchParam.workDeptCd = this.$store.getters.user.deptCd;
            this.woSearchParam.plantCd = this.$store.getters.user.plantCd;
            this.pmSearchParam.plantCd = this.$store.getters.user.plantCd;
            this.searchParam2.plantCd = this.$store.getters.user.plantCd;
            // this.period = [
            //     this.$comm.getPrevMonth(-6),
            //     this.$comm.getThisMonth(),
            // ];
            this.period = ["2024-10", "2024-11"];
            this.mtbfGrid.listUrl = selectConfig.sts.wha.mttrmtbf.url;
            this.mtbfGrid.graphUrl = selectConfig.sts.wha.mttrmtbf.graph.url;
            this.woListUrl = selectConfig.sts.rpa.workorder.url;
            this.workOrderUrl = selectConfig.wod.workorder.plan.list.url;
            this.workDailyUrl = selectConfig.wod.workorder.daily.list.url;
            this.woFullFillUrl = selectConfig.main.wo.url;
            this.pmFullFillUrl = selectConfig.main.pm.url;

            this.listUrl =
                selectConfig.wod.workorder.plan.list.url + "/calendar";
            // this.listUrl = selectConfig.wod.oldworkorder.plan.list.url + '/calendar';
            this.listUrl2 = selectConfig.sop.min.equipment.plan.list.url;
            this.listUrl3 = selectConfig.pm.mst.list.url + "/calendar";

            this.ordPlanList();
            this.ordDailyList();
            this.mtbfList();
            this.setMonths();
            // this.mtbfGraphList();
            this.calendarList();
            this.woFullFillList();
            this.pmFullFillList();
        },
        ordDailyList() {
            this.$http.url = this.workDailyUrl;
            this.$http.type = "GET";
            this.$http.param = this.dailySearchParam;
            this.$http.request((_result) => {
                this.dailyPlanGrid.data = _result.data;
            });
        },
        ordPlanList() {
            this.$http.url = this.workOrderUrl;
            this.$http.type = "GET";
            this.$http.param = this.plantSearchParam;
            this.$http.request((_result) => {
                this.ordPlanGrid.data = _result.data;
            });
        },
        setMonths() {
            let startYear = Number(this.period[0].substring(0, 4));
            let startMonth = Number(this.period[0].substring(5));

            let lastYear = Number(this.period[1].substring(0, 4));
            let lastMonth = Number(this.period[1].substring(5));

            this.searchParam2.months = [];
            if (startYear === lastYear) {
                for (let month = startMonth; month <= lastMonth; month++) {
                    let monthStr;
                    if (month < 10) {
                        monthStr = "0" + month;
                    } else {
                        monthStr = month;
                    }
                    this.searchParam2.months.push(startYear + "-" + monthStr);
                }
            } else {
                for (let year = startYear; year <= lastYear; year++) {
                    if (year === startYear) {
                        for (let month = startMonth; month <= 12; month++) {
                            let monthStr;
                            if (month < 10) {
                                monthStr = "0" + month;
                            } else {
                                monthStr = month;
                            }
                            this.searchParam2.months.push(
                                year + "-" + monthStr
                            );
                        }
                    } else if (year === lastYear) {
                        for (let month = 1; month <= lastMonth; month++) {
                            let monthStr;
                            if (month < 10) {
                                monthStr = "0" + month;
                            } else {
                                monthStr = month;
                            }
                            this.searchParam2.months.push(
                                year + "-" + monthStr
                            );
                        }
                    } else {
                        for (let month = 1; month <= 12; month++) {
                            let monthStr;
                            if (month < 10) {
                                monthStr = "0" + month;
                            } else {
                                monthStr = month;
                            }

                            this.searchParam2.months.push(
                                year + "-" + monthStr
                            );
                        }
                    }
                }
            }
            this.woList();
        },
        woList() {
            if (!this.period || this.period.length === 0) {
                window.getApp.$emit("ALERT", {
                    title: "안내", // 안내
                    message: "해당년월을 선택하세요.", // 해당년월을 선택하세요.
                    type: "warning", // success / info / warning / error
                });
                return;
            }
            this.$http.url = this.woListUrl;
            this.$http.type = "GET";
            this.$http.param = this.searchParam2;
            this.$http.request((_result) => {
                this.woChart.chartOptions.xaxis.categories = this.$_.map(
                    _result.data,
                    "plan_start_dt"
                );
                this.$set(
                    this.woChart.series[0],
                    "data",
                    this.$_.map(_result.data, "plan_count")
                );
                this.$set(
                    this.woChart.series[1],
                    "data",
                    this.$_.map(_result.data, "complete_count")
                );

                this.$refs["woChart"].refresh();
                this.woChart.chartWidth = "90%";
            });
        },
        woFullFillList() {
            this.$http.url = this.woFullFillUrl;
            this.$http.type = "GET";
            this.$http.param = this.woSearchParam;
            this.$http.request((_result) => {
                this.woData = _result.data;
                this.$set(this.woChart.series[0], "data", [
                    Number(_result.data.allCnt),
                    Number(_result.data.completeCnt),
                    Number(_result.data.noCompleteCnt),
                ]);
                // this.$refs['woChart'].refresh();
                // this.woChart.chartWidth = '90%';
            });
        },
        pmFullFillList() {
            this.$http.url = this.pmFullFillUrl;
            this.$http.type = "GET";
            this.$http.param = this.pmSearchParam;
            this.$http.request((_result) => {
                this.pmData = _result.data;
                this.$set(this.pmChart.series[0], "data", [
                    Number(_result.data.allCnt),
                    Number(_result.data.completeCnt),
                    Number(_result.data.noCompleteCnt),
                ]);
                // this.$refs['pmChart'].refresh();
                // this.pmChart.chartWidth = '90%';
            });
        },
        mtbfList() {
            this.$http.url = this.mtbfGrid.listUrl;
            this.$http.type = "GET";
            this.$http.param = {
                plantCd: this.searchParam.plantCd,
                isMain: "Y",
            };
            this.$http.request((_result) => {
                this.mtbfGrid.data = _result.data;
            });
        },
        // mtbfGraphList() {
        //   this.$http.url = this.graphUrl.listUrl;
        //   this.$http.type = 'GET';
        //   this.$http.param = {
        //     plantCd: this.searchParam2.plantCd,
        //   };
        //   this.$http.request((_result) => {
        //     this.$_.forEach(_result.data, _item => {
        //       this.mtbfChart.series.push({
        //         name: _item.name,
        //       })
        //     })
        //   })
        // },
        goPage(_page) {
            this.$comm.movePage(this.$router, _page);
        },
        rowClick1(row) {
            this.popupOptions.title = "정비오더 상세";
            this.popupOptions.param = {
                workPlanId: row ? row.workPlanId : "",
                plantCd: row ? row.plantCd : "",
                woWorkPlanStepCd: row ? row.woWorkPlanStepCd : "WWPSC00001",
                woRequestId: row ? row.woRequestId : "",
                copyFlag: row ? row.copyFlag : "N",
                firstCopy: row
                    ? row.firstCopy
                        ? row.firstCopy
                        : false
                    : false,
            };
            this.popupOptions.target = () =>
                import(`${"@/pages/wod/plan/workOrderPlanDetail.vue"}`);
            this.popupOptions.isFull = true;
            this.popupOptions.visible = true;
            this.popupOptions.closeCallback = this.closePopup;
        },
        rowClick2(row) {
            this.popupOptions.title = "일일작업 결과";
            this.popupOptions.isFull = false;
            this.popupOptions.width = "70%";
            this.popupOptions.param = {
                workResultWorkId: row ? row.workResultWorkId : "",
                workDt: row ? row.workDt : "",
                workResultDailyId: row ? row.workResultDailyId : "",
            };
            this.popupOptions.target = () =>
                import(`${"@/pages/wod/plan/workOrderDailyPlanDetail.vue"}`);
            this.popupOptions.visible = true;
            this.popupOptions.closeCallback = this.closePopup;
        },
        closePopup() {
            this.popupOptions.target = null;
            this.popupOptions.visible = false;
            this.ordPlanList();
            this.ordDailyList();
            this.mtbfList();
            this.woFullFillList();
            this.pmFullFillList();
        },
        calendarList() {
            if (this.$refs.calendar !== undefined) {
                this.calendarParam.plantDts = [
                    this.$refs.calendar.lastStart,
                    this.$refs.calendar.lastEnd,
                ];
            } else {
                if (Number(this.start.month) < 10)
                    this.start.month = "0" + this.start.month;
                this.calendarParam.plantDts = [
                    this.start.year + "-" + this.start.month + "-01",
                    this.start.year + "-" + this.start.month + "-31",
                ];
            }
            this.events = [];
            if (this.calendarParam.woType == "WT00000001") {
                this.getSearch("A");
            } else if (this.calendarParam.woType == "WT00000002") {
                this.getSearch("B");
            } else if (this.calendarParam.woType == "WT00000003") {
                this.getSearch("C");
            }
        },
        getSearch(_type) {
            if (_type == "A") {
                // 작업 조회
                this.$http.url = this.listUrl;
                this.$http.type = "GET";
                this.$http.param = this.calendarParam;
                this.$http.request((_result) => {
                    this.$_.forEach(_result.data, (_item) => {
                        this.events.push({
                            type: "A",
                            title:
                                "[" + _item.woWorkTypeName + "] " + _item.title,
                            start: _item.start,
                            end: _item.end,
                            color: this.getColors(_item.woWorkPlanStepCd),
                            icon: this.getIcons(_item.woWorkPlanStepCd),
                            workPlanId: _item.workPlanId,
                            plantCd: _item.plantCd,
                            woWorkPlanStepCd: _item.woWorkPlanStepCd,
                            woWorkPlanStepName: _item.woWorkPlanStepName,
                            woRequestId: _item.woRequestId,
                            workDeptName: _item.workDeptName,
                            equipmentName: _item.equipmentName,
                            copyFlag: _item.copyFlag,
                        });
                    });
                });
            } else if (_type == "B") {
                // 예방점검 조회
                this.$http.url = this.listUrl2;
                this.$http.type = "GET";
                this.$http.param = this.calendarParam;
                this.$http.request((_result) => {
                    this.$_.forEach(_result.data, (_item) => {
                        this.events.push({
                            type: "B",
                            title: _item.equipmentName,
                            start:
                                _item.checkStatusCd == "MCSC000015"
                                    ? _item.checkDate
                                    : _item.checkScheduleDate,
                            end:
                                _item.checkStatusCd == "MCSC000015"
                                    ? _item.checkDate
                                    : _item.checkScheduleDate,
                            color: this.getColors(_item.checkStatusCd),
                            icon: this.getIcons(_item.checkStatusCd),
                            workPlanId: _item.minEquipmentCheckId,
                            plantCd: _item.plantCd,
                            woWorkPlanStepCd: _item.checkStatusCd,
                            woWorkPlanStepName: _item.checkStatusName,
                            woRequestId: _item.checkResultCd,
                            workDeptName: _item.checkDeptName,
                            equipmentName: _item.equipmentCdName,
                            copyFlag: "N",
                        });
                    });
                });
            } else if (_type == "C") {
                // 예방점검 조회
                this.$http.url = this.listUrl3;
                this.$http.type = "GET";
                this.$http.param = this.calendarParam;
                this.$http.request((_result) => {
                    if (
                        _result.data.calendarList &&
                        _result.data.calendarList.length > 0
                    ) {
                        this.$_.forEach(_result.data.calendarList, (pmData) => {
                            if (
                                this.$refs.calendar.lastStart <=
                                    pmData.pmNextDt &&
                                pmData.pmNextDt <= this.$refs.calendar.lastEnd
                            ) {
                                this.events.push({
                                    type: "D",
                                    title: pmData.pmMstName + " [차기정비예정]",
                                    start: pmData.pmNextDt,
                                    end: pmData.pmNextDt,
                                    color: "grey-6",
                                    icon: "group_add",
                                });
                            }
                            if (
                                this.$refs.calendar.lastStart <=
                                    pmData.pmOrderDate &&
                                pmData.pmOrderDate <=
                                    this.$refs.calendar.lastEnd
                            ) {
                                this.events.push({
                                    type: "D",
                                    title:
                                        pmData.pmMstName +
                                        " [차기오더작성예정]",
                                    start: pmData.pmOrderDate,
                                    end: pmData.pmOrderDate,
                                    color: "grey-6",
                                    icon: "group_add",
                                });
                            }
                        });
                    }
                    if (
                        _result.data.workOrderList &&
                        _result.data.workOrderList.length > 0
                    ) {
                        this.$_.forEach(_result.data.workOrderList, (_item) => {
                            this.events.push({
                                type: "C",
                                title:
                                    "[" +
                                    _item.woWorkTypeName +
                                    "] " +
                                    _item.title,
                                start: _item.start,
                                end: _item.end,
                                color: this.getColors(_item.woWorkPlanStepCd),
                                icon: this.getIcons(_item.woWorkPlanStepCd),
                                workPlanId: _item.workPlanId,
                                plantCd: _item.plantCd,
                                woWorkPlanStepCd: _item.woWorkPlanStepCd,
                                woWorkPlanStepName: _item.woWorkPlanStepName,
                                woRequestId: _item.woRequestId,
                                workDeptName: _item.workDeptName,
                                equipmentName: _item.equipmentName,
                                copyFlag: _item.copyFlag,
                            });
                        });
                    }
                });
            }
        },
        getColors(_code) {
            var returnColor = "";
            switch (_code) {
                case "WWPSC00000": // 정비요청
                    returnColor = "grey-6";
                    break;
                case "WWPSC00001": // 작업계획중
                    returnColor = "orange";
                    break;
                case "WWPSC00002": // 작업계획 승인중
                    returnColor = "blue";
                    break;
                case "WWPSC00004": // 작업결과 승인중
                    returnColor = "yellow";
                    break;
                case "WWPSC00003": // 작업진행중
                    returnColor = "deep-purple-6";
                    break;
                case "WWPSC00009": // 작업완료
                    returnColor = "green";
                    break;
                case "WWPSC99999": // 작업취소
                case "WWPSC99998": // 작업계획취소
                    returnColor = "red-6";
                    break;
                case "MCSC000005": // 예방점검 계획수립
                    returnColor = "orange";
                    break;
                case "MCSC000010": // 점검중
                    returnColor = "deep-purple-6";
                    break;
                case "MCSC000015": // 점검완료
                    returnColor = "green";
                    break;
                case "MCSC000020": // 지연
                    returnColor = "red-6";
                    break;
            }

            return returnColor;
        },
        getIcons(_code) {
            var returnColor = "";
            switch (_code) {
                case "WWPSC00000": // 정비요청
                    returnColor = "border_color";
                    break;
                case "WWPSC00001": // 작업계획중
                    returnColor = "format_list_bulleted";
                    break;
                case "WWPSC00002": // 작업계획 승인중
                    returnColor = "thumb_up_off_alt";
                    break;
                case "WWPSC00003": // 작업진행중
                    returnColor = "manage_accounts";
                    break;
                case "WWPSC00009": // 작업완료
                    returnColor = "task_alt";
                    break;
                case "WWPSC99999": // 작업취소
                case "WWPSC99998": // 작업계획취소
                    returnColor = "event_busy";
                    break;
                case "MCSC000005": // 예방점검 계획수립
                    returnColor = "format_list_bulleted";
                    break;
                case "MCSC000010": // 점검중
                    returnColor = "manage_accounts";
                    break;
                case "MCSC000015": // 점검완료
                    returnColor = "task_alt";
                    break;
                case "MCSC000020": // 지연
                    returnColor = "event_busy";
                    break;
            }
            return returnColor;
        },
        onChange({ start }) {
            this.start = start;
            this.updateTitle();
            this.calendarList();
        },
        onPrev() {
            this.$refs.calendar.prev();
        },
        onNext() {
            this.$refs.calendar.next();
        },
        onToday() {
            this.$refs.calendar.moveToToday();
        },
        updateTitle() {
            this.title = this.start.year + "년 " + this.start.month + "월";
        },
        isCssColor(color) {
            return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/);
        },
        badgeClasses(infoEvent, type) {
            const color =
                infoEvent.event !== undefined
                    ? infoEvent.event.color
                    : "transparent";
            const cssColor = this.isCssColor(color);
            const isHeader = type === "header";

            return {
                [`text-white bg-${color}`]: !cssColor,
                "full-width":
                    !isHeader && (!infoEvent.side || infoEvent.side === "full"),
                "left-side": !isHeader && infoEvent.side === "left",
                "right-side": !isHeader && infoEvent.side === "right",
                "cursor-pointer":
                    infoEvent.event !== undefined &&
                    infoEvent.event.type !== "D",
                "q-day-event-void": infoEvent.event === undefined,
            };
        },
        badgeStyles(
            infoEvent,
            type,
            weekLength,
            timeStartPos,
            timeDurationHeight
        ) {
            const s = {};
            if (timeStartPos) {
                s.top = timeStartPos(infoEvent.event.time) + "px";
            }
            if (timeDurationHeight) {
                s.height = timeDurationHeight(infoEvent.event.duration) + "px";
            }
            if (infoEvent.size !== undefined) {
                s.width = (100 / weekLength) * infoEvent.size + "% !important";
            }
            return s;
        },
        getWeekEvents(week) {
            const tsFirstDay = QCalendarm.parsed(week[0].date + " 00:00");
            const tsLastDay = QCalendarm.parsed(
                week[week.length - 1].date + " 23:59"
            );
            const firstDay = QCalendarm.getDayIdentifier(tsFirstDay);
            const lastDay = QCalendarm.getDayIdentifier(tsLastDay);

            const eventsWeek = [];
            this.events.forEach((event, id) => {
                const tsStartDate = QCalendarm.parsed(event.start + " 00:00");
                const tsEndDate = QCalendarm.parsed(event.end + " 23:59");
                const startDate = QCalendarm.getDayIdentifier(tsStartDate);
                const endDate = QCalendarm.getDayIdentifier(tsEndDate);

                if (
                    this.isBetweenDatesWeek(
                        startDate,
                        endDate,
                        firstDay,
                        lastDay
                    )
                ) {
                    const left = QCalendarm.daysBetween(
                        tsFirstDay,
                        tsStartDate,
                        true
                    );
                    const right = QCalendarm.daysBetween(
                        tsEndDate,
                        tsLastDay,
                        true
                    );

                    eventsWeek.push({
                        id, // index event
                        left, // Position initial day [0-6]
                        right, // Number days available
                        size: week.length - (left + right), // Size current event (in days)
                        event, // Info
                    });
                }
            });
            const events = [];
            if (eventsWeek.length > 0) {
                const infoWeek = eventsWeek.sort((a, b) => a.left - b.left);
                infoWeek.forEach((event, i) => {
                    this.insertEvent(events, week.length, infoWeek, i, 0, 0);
                });
            }
            return events;
        },
        insertEvent(events, weekLength, infoWeek, index, availableDays, level) {
            const iEvent = infoWeek[index];
            if (iEvent !== undefined && iEvent.left >= availableDays) {
                if (iEvent.left - availableDays) {
                    events.push({ size: iEvent.left - availableDays });
                }
                events.push({ size: iEvent.size, event: iEvent.event });
                if (level !== 0) {
                    infoWeek.splice(index, 1);
                }
                const currentAvailableDays = iEvent.left + iEvent.size;
                if (currentAvailableDays < weekLength) {
                    const indexNextEvent = QCalendarm.indexOf(
                        infoWeek,
                        (e) =>
                            e.id !== iEvent.id && e.left >= currentAvailableDays
                    );
                    this.insertEvent(
                        events,
                        weekLength,
                        infoWeek,
                        indexNextEvent !== -1 ? indexNextEvent : index,
                        currentAvailableDays,
                        level + 1
                    );
                }
            } else {
                events.push({ size: weekLength - availableDays });
            }
        },
        isBetweenDates(date, start, end) {
            return date >= start && date <= end;
        },
        isBetweenDatesWeek(dateStart, dateEnd, weekStart, weekEnd) {
            return (
                (dateEnd < weekEnd && dateEnd >= weekStart) ||
                dateEnd === weekEnd ||
                (dateEnd > weekEnd && dateStart <= weekEnd)
            );
        },
        openJobDetail(row) {
            if (row.event.type == "D") return;
            if (row.event.type == "A" || row.event.type == "C") {
                this.popupOptions.title = "정비오더 상세";
                this.popupOptions.suffixChip = row.event.woWorkPlanStepName;
                this.popupOptions.param = {
                    workPlanId: row.event.workPlanId,
                    plantCd: row.event.plantCd,
                    woWorkPlanStepCd: row.event.woWorkPlanStepCd,
                    woRequestId: row.event.woRequestId,
                    copyFlag: row.event ? row.event.copyFlag : "N",
                    firstCopy: false,
                };
                this.popupOptions.target = () =>
                    import(`${"@/pages/wod/plan/workOrderPlanDetail.vue"}`);
            } else {
                this.popupOptions.title = "설비점검결과 상세"; // 설비점검계획 상세
                this.popupOptions.param = {
                    minEquipmentCheckId: row.event.workPlanId
                        ? row.event.workPlanId
                        : "",
                    title: "설비점검결과",
                    selectData: [],
                    noPlan: "N",
                };
                this.popupOptions.target = () =>
                    import(
                        `${"@/pages/sop/mim/inspection/equipmentResultDetail.vue"}`
                    );
            }
            this.popupOptions.isFull = true;
            this.popupOptions.visible = true;
            this.popupOptions.closeCallback = this.closeCalendarPopup;
        },
        closeCalendarPopup() {
            this.popupOptions.target = null;
            this.popupOptions.visible = false;
            this.calendarList();
        },
    },
};
</script>
<style lang="sass">
.itemlist
  width: 280px
  float: right
  font-size: 1.0em !important
  font-weight: 600
  color: black
.icountup
  padding-bottom: 10px
  padding-left: 30px
  font-size: 3.5em
  margin: 0
  color: #4d63bc
  font-family: cursive
.card-more-txt
  font-size:12px
  margin-top: 3px
  padding-right: 10px
.no-padding
  padding: 0px 0px 6px 0px !important
.maindashboard .cardcontents
  box-shadow: 0 !important
  border: 1px solid rgba(0,0,0,0.12) !important
  border-radius: 10px !important
  margin-top: 6px
  margin-bottom: 6px
  margin-right: 6px
  .card-body
    padding: 10px !important
    border-radius: 0 0 10px 10px !important
    background: #fff
  .card-body-box
    border: 1px solid rgba(0,0,0,0.12) !important
    border-radius: 10px !important
    margin-top: 6px
    margin-bottom: 6px
    margin-left: 6px
    height: 100px
  .card-header:before
    font-family: "Material Icons"
    content: "\e876"
  .card-header
    border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    color: #0072c6
    background: #f8f8f9
    display: inline-block
    padding-top: 8px
    width: 100%
    .card-more
      float: right !important
      cursor: pointer
      color: #0394ff
.main-header-input
  margin-top: -5px
  padding-right: 5px
.main-header-input2
  margin-top: -5px
  padding-right: 5px
  width: 150px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.main-header-input3
  margin-top: -5px
  padding-right: 5px
  width: 230px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.main-header-input4
  margin-top: -5px
  padding-right: 5px
  width: 190px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

.directorMainLayer
  .workPermit-carousel
    border-radius: 10px
    height: var(--map-height) !important
    .q-carousel__slide
      width: calc(var(--map-ratio) * var(--map-height)) !important
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    .q-carousel__slides-container
      .q-panel.scroll
        overflow: hidden !important


.directorMainLayer
  .caption-work2
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #fff
    padding: 10px
  .caption-work
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

.directorMainLayer
  .mainmapstart
    float: right
    margin-right: 20px

.directorMainLayer
  .mainMarkImage
    padding: 0px !important
    text-align: center
    font-size: 20px
    height: 30px !important
    position: absolute
    padding: 16px
    color: #C10015
    z-index: 3 !important
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important

.directorMainLayer
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out


.main-map-card
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important
</style>
<style scoped>
.widget-numbers {
    font-size: 20px;
}
.impr-item {
    height: 100% !important;
}
.my-class {
    /* background-color: #9E9E9E; */
    border: 2px solid rgba(255, 255, 255, 0.7);
    background-color: rgba(40, 48, 70, 0.2);
    border-radius: 10px;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    position: absolute;
    text-align: center;
    color: black;
    cursor: pointer;
    /* z-index: 1 !important; */
}
.my-class div {
    width: 100%;
    border-radius: 10px 10px 0 0;
    color: #fff;
    background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
    /* opacity: 0.5; */
    background-color: rgba(115, 103, 240, 0.5);
    /* color:azure; */
}
.chip-task {
    margin-left: 8px !important;
}
.app-main__inner .task-detail {
    z-index: 5;
    width: 250px;
    position: relative;
}
.app-main__inner .task-detail .q-banner {
    background-color: #283046;
    border-radius: 5px;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding: 0px !important;
    color: #fff !important;
}
.app-main__inner .task-detail .q-banner .col-auto {
    padding: 0px !important;
}
.mainmapbannerTitle {
    width: 100%;
    color: #fff;
    background-color: #343d55;
    padding: 10px !important;
    border-radius: 3px;
    font-size: 0.9em !important;
}
.open-task {
    left: 100%;
    width: 80%;
    transform: translate(-100%, 0);
    position: absolute;
    z-index: 1000;
    padding: 10px !important;
    border-radius: 15px !important;
    background: #fff;
}
.maintablebody::-webkit-scrollbar {
    width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
    background: #343d55 !important;
    border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
    background-color: #161d31 !important;
    background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
    text-decoration: underline;
    cursor: pointer;
}
.mapTitleDiv {
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 10px;
    background-color: #5e5f61 !important;
    color: #fff;
    padding: 5px 10px;
    font-weight: 700;
    z-index: 9999;
    font-size: 12px;
}

.progress-shell {
    height: 40px;
    width: 200px;
    border: 1px solid #aaa;
    border-radius: 13px;
}
.progress-bar {
    height: 38px;
    background: #fff;
    border-radius: 13px;
    overflow: hidden;
    position: relative;
}
.progress-bar::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 38px;
    width: 0;
    background: linear-gradient(to right, #adcffc, #1d7bee);
    animation: progress 3s ease forwards;
}
@keyframes progress {
    0% {
        width: 0px;
    }
    100% {
        width: 100%;
    }
}

.progress-bar-span {
    float: right;
    color: black;
    /* color: #fff; */
    font-size: 1.2em;
    z-index: 99;
}
</style>

<style lang="sass">
.maincard
  margin-bottom: 1px
  min-height: 90px
  background-color: transparent !important
.maincard2
  margin-bottom: 1px
  min-height: 50px
  background-color: transparent !important
.maincard .card-body
  background: #fff
  border-radius: 5px
  padding: 15px !important
  .fw-bold
    font-weight: 600
  .material-icons
    font-size: 3em
    color: #fff
    cursor: pointer
.maincard .card-body.border-color-blue
  background: #0061f2
  color: #fff
.maincard .card-body.border-color-red
  background: #d92550
  color: #fff
.maincard .card-body.border-color-yellow
  background: #f7b924
  color: #fff
.maincard .card-body.border-color-green
  background: #3ac47d
  color: #fff
</style>
